var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "masthead",
        { attrs: { title: "Invitations", "show-search": false } },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal.inviteModal",
                  modifiers: { inviteModal: true },
                },
              ],
              attrs: { "data-cy": "invite-people-btn", variant: "primary" },
            },
            [_vm._v("\n      Invite People\n    ")]
          ),
        ],
        1
      ),
      _c(
        "b-tabs",
        [
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _vm._v("\n        Shared with Me\n        "),
                      _vm.invitationsReceivedCount > 0
                        ? _c(
                            "b-badge",
                            {
                              style: { right: _vm.$mq == "sm" ? "20%" : "40%" },
                              attrs: { pill: "", variant: "danger" },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.invitationsReceivedCount) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("ct-table", {
                attrs: {
                  items: _vm.invitationsReceived,
                  fields: _vm.receivedFields,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  loaded: _vm.loaded,
                },
                on: {
                  "update:sortBy": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function ($event) {
                    _vm.sortDesc = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(message)",
                    fn: function (row) {
                      return [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              row.item.owner.first_name +
                                " " +
                                row.item.owner.last_name
                            )
                          ),
                        ]),
                        _vm._v(" would like to grant you access to "),
                        _c("strong", [_vm._v(_vm._s(row.item.company_name))]),
                      ]
                    },
                  },
                  {
                    key: "cell(actions)",
                    fn: function (row) {
                      return [
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-1 mr-1 pt-0 pb-0",
                            attrs: { variant: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.submitChoice(row.item, "accept")
                              },
                            },
                          },
                          [_vm._v("\n            Accept\n          ")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-1 mr-1 pt-0 pb-0",
                            attrs: { variant: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.submitChoice(row.item, "decline")
                              },
                            },
                          },
                          [_vm._v("\n            Decline\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Shared with Others" } },
            [
              _c("ct-table", {
                attrs: {
                  items: _vm.invitationsSent,
                  fields: _vm.sentFields,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  loaded: _vm.loaded,
                },
                on: {
                  "update:sortDesc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function ($event) {
                    _vm.sortDesc = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(actions)",
                    fn: function (row) {
                      return [
                        _vm.isPending(row)
                          ? _c(
                              "b-button",
                              {
                                staticClass: "ml-1 mr-1 pt-0 pb-0",
                                attrs: { variant: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitChoice(row.item, "cancel")
                                  },
                                },
                              },
                              [_vm._v("\n            Cancel\n          ")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "ml-2 mr-1 pt-0 pb-0",
                                staticStyle: {
                                  textTransform: "capitalize",
                                  "border-bottom": "1px dashed",
                                },
                                attrs: { id: row.item.id },
                              },
                              [_vm._v(_vm._s(row.item.invite_status))]
                            ),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: row.item.id,
                              placement: "right",
                              offset: "60",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.statusTooltip(row)) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("invite-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }