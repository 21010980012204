<template>
  <b-container>
    <masthead title="Invitations" :show-search="false">
      <b-button
        v-b-modal.inviteModal
        data-cy="invite-people-btn"
        variant="primary"
      >
        Invite People
      </b-button>
    </masthead>

    <b-tabs>
      <b-tab>
        <template v-slot:title>
          Shared with Me
          <b-badge v-if="invitationsReceivedCount>0" pill variant="danger" :style="{ right: $mq == 'sm' ? '20%' : '40%' }">
            {{ invitationsReceivedCount }}
          </b-badge>
        </template>

        <ct-table
          :items="invitationsReceived"
          :fields="receivedFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :loaded="loaded"
        >
          <template slot="cell(message)" slot-scope="row">
            <strong>{{ row.item.owner.first_name + ' ' + row.item.owner.last_name }}</strong> would like to grant you access to <strong>{{ row.item.company_name }}</strong>
          </template>

          <template slot="cell(actions)" slot-scope="row">
            <b-button variant="primary" class="ml-1 mr-1 pt-0 pb-0" @click="submitChoice(row.item, 'accept')">
              Accept
            </b-button>
            <b-button variant="danger" class="ml-1 mr-1 pt-0 pb-0" @click="submitChoice(row.item, 'decline')">
              Decline
            </b-button>
          </template>
        </ct-table>
      </b-tab>
      <b-tab title="Shared with Others">
        <ct-table
          :items="invitationsSent"
          :fields="sentFields"
          :sort-by.sinc="sortBy"
          :sort-desc.sync="sortDesc"
          :loaded="loaded"
        >
          <template slot="cell(actions)" slot-scope="row">
            <b-button v-if="isPending(row)" variant="primary" class="ml-1 mr-1 pt-0 pb-0" @click="submitChoice(row.item, 'cancel')">
              Cancel
            </b-button>
            <span v-else :id="row.item.id" class="ml-2 mr-1 pt-0 pb-0" style="textTransform: capitalize; border-bottom: 1px dashed">{{ row.item.invite_status }}</span>
            <b-tooltip :target="row.item.id" placement="right" offset="60">
              {{ statusTooltip(row) }}
            </b-tooltip>
          </template>
        </ct-table>
      </b-tab>
    </b-tabs>

    <invite-modal />
  </b-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import FeatherIcon from '@/components/shared/FeatherIcon'
  import InviteModal from '@/components/InviteModal'
  import Masthead from '@/components/shared/Masthead'
  import CPagination from '../components/shared/Pagination'
  import { formatDateString } from '@/common/modules/formatters'
  import CtTable from '../components/shared/CtTable'

  export default {
    name: 'Invitations',
    components: { CtTable, CPagination, FeatherIcon, InviteModal, Masthead },

    computed: {
      ...mapGetters('invitations', ['invitationsReceived', 'invitationsReceivedCount', 'invitationsSent']),
    },

    data () {
      return {
        loaded: false,
        sortBy: 'created_at',
        sortDesc: true,
        receivedFields:  [
          {
            sortable: true, key: 'created_at', label: 'Received On',
            formatter: value => formatDateString(value),
          },
          { key: 'message', label: 'Message' },
          { key: 'actions', label: '' },
        ],
        sentFields: [
          {
            sortable: true, key: 'created_at', label: 'Sent On',
            formatter: value => formatDateString(value),
          },
          { sortable: true, key: 'company_name', label: 'Shared Company' },
          {
            sortable: true, key: 'role', label: 'Role',
            formatter: value => _.startCase(value),
          },
          { sortable: true, key: 'email_address', label: 'Email' },
          {
            key: 'actions', label: 'Status',
          },
        ],
      }
    },

    methods: {
      ...mapActions('invitations', ['loadInvitations', 'submitInvitationChoice']),

      async submitChoice(item, suffix) {
        await this.submitInvitationChoice({ invite: item, choice: suffix })
      },

      isPending(row) {
        return row.item.invite_status === "invited"
      },

      statusTooltip(row) {
        let tooltip = 'This invitation was not accepted. You may try again at any time by creating a new invitation.'

        if (row.item.invite_status === "accepted") {
          tooltip = 'To manage invitations that have already been accepted, please refer to "Shared Companies" under the Settings tab.'
        }

        return tooltip
      },

      async load() {
        this.loaded = false
        await this.loadInvitations(this)
        this.loaded = true
      },
    },

    async mounted() {
      await this.load()
    },

  }
</script>

<style lang="scss" scoped>
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 550px) {
  }
</style>
