<template>
  <vue-multiselect
    v-model="selection"
    :placeholder="placeholderText"
    :label="label"
    :track-by="identifier"
    :options="options"
    :multiple="multiple"
    :loading="isLoading"
    @input="inputEvent"
    @select="selectEvent"
    @remove="removeEvent"
    @searchChange="searchChangeEvent"
    @open="openEvent"
    @close="closeEvent"
  />
</template>

<script>
/*

# Multiselect
This is a wrapper for the Vue-Multiselect component with some additional custom functionality.
Reference: https://vue-multiselect.js.org/

## Props
  datasource:array,        An array of objects to display in the list.
  identifier:any,          What key/value in datasource can uniquely identify each object.
  label:any,               The key/value in the datasource that will be displayed on selection
  multiple:boolean,        Allow multiple selections? (default: false)
  selectAll:boolean,       Display a "select all" option. (default: true)
  showCategory:boolean,    Show the category. (default: false)
  placeholderText:string   The placeholder (i.e. "Select a ____".
  isLoading:boolean,       Sync up a reactive loading spinner.

## Events
Since this is just a wrapper, we attempt to pass through any events generated by VueMultiselect.
@inputEvent is called whenever the user adds or removes an item. This will broadcast the
atomic change (value, id) as well as the current state of the multiselect.

  @input="function"
  @select="function"
  @remove="function"
  @searchChange="function"
  @open="function"
  @close="function"

TODO: Add support for the Vue-Multiselect slots: https://vue-multiselect.js.org/#sub-slots
TODO: Bring back "variant" functionality. Options are "primary" and "success" for applying colors.

*/

import VueMultiselect from 'vue-multiselect'

export default {
  name: 'Multiselect',
  components: { VueMultiselect },
  props: {
    datasource: {
      type: Array,
      default: () => [],
    },
    identifier: {
      type: String,
    },
    label: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    selectAll: {
      type: Boolean,
    },
    placeholderText: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      input: '',
      selection: [],
    }
  },
  computed: {
    // Add meta options like 'Select All'.
    options: function() {
      let optionsList = this.datasource

      if (this.selectAll) {
        optionsList = this.addSelectAllOption(optionsList)
        optionsList = this.addDeselectAllOption(optionsList)
      }

      return optionsList
    },
  },
  methods: {
    inputEvent: function() {
      this.updateSelected()
      // Custom events
      this.$emit('change', this.selection)
      // Wrapped events.
      this.$emit('input', this.selection)
    },
    selectEvent: function(selectedOption) {
      this.updateSelected()

      this.$emit('select', selectedOption)
    },
    removeEvent: function(removedOption) {
      this.$emit('remove', removedOption)
    },
    searchChangeEvent: function(searchQuery) {
      this.$emit('searchChange', searchQuery)
    },
    openEvent: function(searchQuery) {
      this.$emit('open', searchQuery)
    },
    closeEvent: function(value) {
      this.updateSelected()

      this.$emit('close', value)
    },
    // Process 'select-all' and 'deselect-all' after a user selects them.
    updateSelected: function() {
      if (this.selection != null && this.selection.filter(e => e[this.identifier] === 'deselect-all').length > 0) {
        this.selection = []
      }

      if (this.selection != null && this.selection.filter(e => e[this.identifier] === 'select-all').length > 0) {
        this.selection = this.datasource.slice(0)
      }

      // Filter out select-all and deselect-all from the actual selection.
      this.selection = this.selection.filter(e => e[this.identifier] !== 'deselect-all' && e[this.identifier] !== 'select-all')
    },
    addSelectAllOption: function(list) {
      let optionsList = list
      if (optionsList.filter(e => e[this.identifier] === 'select-all').length === 0) {
        const selectAll = {}
        selectAll[this.identifier] = 'select-all'
        selectAll[this.label] = 'Select all'
        optionsList.unshift(selectAll)
      }

      if (this.selection.length === optionsList.length) {
        optionsList = optionsList.filter(e => e[this.identifier] !== 'select-all')
      }

      return optionsList
    },
    addDeselectAllOption: function(list) {
      let optionsList = list
      if (optionsList.filter(e => e[this.identifier] === 'deselect-all').length === 0) {
        const deselectAll = {}
        deselectAll[this.identifier] = 'deselect-all'
        deselectAll[this.label] = 'Deselect all'
        optionsList.unshift(deselectAll)
      }

      if (this.selection.length === 0) {
        optionsList = optionsList.filter(e => e[this.identifier] !== 'deselect-all')
      }

      return optionsList
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
