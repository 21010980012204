<template>
  <b-modal
    id="inviteModal"
    ref="invitePeopleModal"
    data-cy="invite-people-modal"
    title="Invite People"
    size="lg"
    hide-footer
  >
    <b-form autocomplete="false">
      <b-card class="mb-4 pb-0">
        <span slot="header">Companies</span>
        <b-form-group
          id="companiesLabel"
          label="Share access to:"
          label-for="companies"
        >
          <multiselect
            identifier="id"
            label="name"
            placeholder-text="Enter any Company"
            :datasource="multiselectDatasource"
            :filters="companies.length === 1 ? companies.map((c)=> c).flat() : []"
            :select-all="companies.length !== 0"
            :is-loading="companies.length === 0"
            @change="companySelectionChanged"
          />
        </b-form-group>
      </b-card>
      <b-card v-for="person in form.people" :key="form.people.indexOf(person)" class="mb-4">
        <b-button
          slot="header"
          variant="link"
          size="sm"
          class="float-right text-danger"
          style="padding-top: 0"
          aria-label="remove button"
          @click="removeFormPerson(person.id)"
        >
          Remove
        </b-button>
        <span slot="header">
          {{ `${form.people.indexOf(person) + 1} of ${form.people.length} People` }}
        </span>

        <b-row>
          <b-col>
            <b-form-group
              :id="`person-${person.id}-firstName-label`"
              label="First Name"
              :label-for="`person-${person.id}-firstName`"
            >
              <b-form-input
                :id="`person-${person.id}-firstName`"
                v-model="person.firstName"
                type="text"
                :state="person.firstName == null ? null : person.firstName.length > 0"
                required
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :id="`person-${person.id}-lastName-label`"
              label="Last Name"
              :label-for="`person-${person.id}-lastName`"
            >
              <b-form-input
                :id="`person-${person.id}-lastName`"
                v-model="person.lastName"
                type="text"
                :state="person.lastName == null ? null : person.lastName.length > 0"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              :id="`person-${person.id}-email-label`"
              label="Email"
              :label-for="`person-${person.id}-email`"
            >
              <b-form-input
                :id="`person-${person.id}-email`"
                v-model="person.emailAddress"
                type="email"
                :state="personState(person)"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Role">
              <b-form-radio-group>
                <b-form-radio
                  v-for="role in roles()"
                  :key="role.value"
                  v-model="person.role"
                  :value="role.value"
                  name="role"
                >
                  <b-tooltip :target="role.value" placement="top" :triggers="'hover focus'">
                    {{ titleFor(role.value) }}
                  </b-tooltip>
                  <b-button
                    :id="role.value"
                    variant="link"
                    class="pt-0"
                    :aria-label="role.text + ' button'"
                    @click="() => person.role = role.value"
                  >
                    {{ role.text }}
                  </b-button>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-button variant="link" aria-label="add contact button" @click="addPerson">
        <feather-icon type="plus-circle" />
        Add another contact
      </b-button>
    </b-form>
    <b-button-group class="float-right">
      <b-button variant="danger" class="mr-2" aria-label="send invite button" @click="cancelInvite">
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="!inviteFormIsValid"
        aria-label="send invite button"
        @click="sendInvite"
      >
        Send Invite
      </b-button>
    </b-button-group>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { validEmail } from '@/common/modules/regexp'
import Multiselect from '@/components/shared/Multiselect'
import FeatherIcon from '@/components/shared/FeatherIcon'
import store from '@/store'

export default {
  name: 'InviteModal',
  components: { Multiselect, FeatherIcon },

  data() {
    const newPerson = this.createPerson()
    return {
      form: {
        companies: [],
        people: [newPerson],
      },
    }
  },

  computed: {
    ...mapGetters('companies', ['companies']),
    multiselectDatasource() {
      if (!this.companies || this.companies.length === 0) {
        return []
      }
      return this.companies
    },
    token() {
      return this.$store.state.session.token !== undefined
    },
    inviteFormIsValid() {
      const companiesSelected = this.form.companies.length > 0
      let invalidPersonData = false
      const emails = []
      const ctx = this
      this.form.people.forEach((person) => {
        if (person.firstName == null || person.firstName.length === 0) {
          invalidPersonData = true
        }
        if (person.lastName == null || person.lastName.length === 0) {
          invalidPersonData = true
        }
        if (
          person.emailAddress == null || (!ctx.validEmail().test(person.emailAddress)) ||
          emails.includes(person.emailAddress)
        ) {
          invalidPersonData = true
        }
        if (person.role == null) {
          invalidPersonData = true
        }
        if (person.emailAddress) {
          emails.push(person.emailAddress)
        }
      })

      return companiesSelected && !invalidPersonData
    },
  },

  async mounted() {
    await this.loadMore(false)
  },

  beforeCreate() {
    this.$store  = store
  },

  methods: {
    ...mapActions('invitations', ['createInvitations']),
    ...mapActions('companies', ['loadMore']),
    personState(person) {
      const emailIsValid = this.validEmail().test(person.emailAddress)

      if ((person && !person.emailAddress) || !person) {
        return null
      }
      if (!emailIsValid) {
        return false
      }
      return (
        this.form.people
          .filter((p) => p.id != person.id)
          .map((p) => p.emailAddress)
          .flat()
          .includes(person.emailAddress) === false
      )
    },
    companySelectionChanged(companies) {
      this.form.companies = companies
    },
    removeFormPerson(personId) {
      this.form.people = this.form.people.filter(
        (person) => person.id !== personId
      )
    },
    addPerson() {
      this.form.people.push(this.createPerson())
    },
    createPerson() {
      return {
        id: global.uuidv1(),
        firstName: null,
        lastName: null,
        emailAddress: null,
        role: null,
      }
    },
    roles() {
      return [
        { value: 'owner', text: 'Owner' },
        { value: 'sharedUser', text: 'Shared User' },
        { value: 'attorney', text: 'Attorney' },
      ]
    },
    titleFor(role) {
      switch (role) {
        case 'owner':
          return 'A Shared Owner has access to view Services, Invoices, and Documents.'
        case 'sharedUser':
          return 'A Shared User has limited settings to receive document and invoice emails, view documents, and pay invoices. They do not have the ability to alter company information, or hire us for services.'
        case 'attorney':
          return 'An Attorney User has very limited settings within the account to receive notices that we have received service of process for your company, and also to log in and view those service of process documents. They also have the ability to pay invoices in the account should it be needed.'
      }
    },
    cancelInvite() {
      this.form.people = []
      this.form = {
        companies: [],
        people: [this.createPerson()],
      }
      this.$refs.invitePeopleModal.hide()
    },
    async sendInvite() {
      this.form.companies = this.form.companies.map((company) => company.id)

      const invitations = []
      const mapping = { owner: 'owner', sharedUser: 'associate', attorney: 'professional' }
      for (const companyId of this.form.companies) {
        for (const person of this.form.people) {
          const params =  {
            companyId,
            email_address: person.emailAddress,
            role:          mapping[person.role],
            first_name:    person.firstName,
            last_name:     person.lastName,
          }
          invitations.push(params)
        }
       }
      try {
        await this.createInvitations(invitations)
      } catch (error) {
        console.log(error)
      }

      this.$refs.invitePeopleModal.hide()
      this.$emit('closed')
    },
    validEmail: validEmail,
  },
}
</script>

<style lang="scss" scoped>
  h5 {
    font-size: 1.25rem;
  }

  @media only screen and (max-width: 550px) {
  }
</style>
