var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "invitePeopleModal",
      attrs: {
        id: "inviteModal",
        "data-cy": "invite-people-modal",
        title: "Invite People",
        size: "lg",
        "hide-footer": "",
      },
    },
    [
      _c(
        "b-form",
        { attrs: { autocomplete: "false" } },
        [
          _c(
            "b-card",
            { staticClass: "mb-4 pb-0" },
            [
              _c("span", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Companies"),
              ]),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "companiesLabel",
                    label: "Share access to:",
                    "label-for": "companies",
                  },
                },
                [
                  _c("multiselect", {
                    attrs: {
                      identifier: "id",
                      label: "name",
                      "placeholder-text": "Enter any Company",
                      datasource: _vm.multiselectDatasource,
                      filters:
                        _vm.companies.length === 1
                          ? _vm.companies.map((c) => c).flat()
                          : [],
                      "select-all": _vm.companies.length !== 0,
                      "is-loading": _vm.companies.length === 0,
                    },
                    on: { change: _vm.companySelectionChanged },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.form.people, function (person) {
            return _c(
              "b-card",
              { key: _vm.form.people.indexOf(person), staticClass: "mb-4" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "float-right text-danger",
                    staticStyle: { "padding-top": "0" },
                    attrs: {
                      slot: "header",
                      variant: "link",
                      size: "sm",
                      "aria-label": "remove button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.removeFormPerson(person.id)
                      },
                    },
                    slot: "header",
                  },
                  [_vm._v("\n        Remove\n      ")]
                ),
                _c("span", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        `${_vm.form.people.indexOf(person) + 1} of ${
                          _vm.form.people.length
                        } People`
                      ) +
                      "\n      "
                  ),
                ]),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: `person-${person.id}-firstName-label`,
                              label: "First Name",
                              "label-for": `person-${person.id}-firstName`,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: `person-${person.id}-firstName`,
                                type: "text",
                                state:
                                  person.firstName == null
                                    ? null
                                    : person.firstName.length > 0,
                                required: "",
                              },
                              model: {
                                value: person.firstName,
                                callback: function ($$v) {
                                  _vm.$set(person, "firstName", $$v)
                                },
                                expression: "person.firstName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: `person-${person.id}-lastName-label`,
                              label: "Last Name",
                              "label-for": `person-${person.id}-lastName`,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: `person-${person.id}-lastName`,
                                type: "text",
                                state:
                                  person.lastName == null
                                    ? null
                                    : person.lastName.length > 0,
                                required: "",
                              },
                              model: {
                                value: person.lastName,
                                callback: function ($$v) {
                                  _vm.$set(person, "lastName", $$v)
                                },
                                expression: "person.lastName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: `person-${person.id}-email-label`,
                              label: "Email",
                              "label-for": `person-${person.id}-email`,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: `person-${person.id}-email`,
                                type: "email",
                                state: _vm.personState(person),
                                required: "",
                              },
                              model: {
                                value: person.emailAddress,
                                callback: function ($$v) {
                                  _vm.$set(person, "emailAddress", $$v)
                                },
                                expression: "person.emailAddress",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "Role" } },
                          [
                            _c(
                              "b-form-radio-group",
                              _vm._l(_vm.roles(), function (role) {
                                return _c(
                                  "b-form-radio",
                                  {
                                    key: role.value,
                                    attrs: { value: role.value, name: "role" },
                                    model: {
                                      value: person.role,
                                      callback: function ($$v) {
                                        _vm.$set(person, "role", $$v)
                                      },
                                      expression: "person.role",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: role.value,
                                          placement: "top",
                                          triggers: "hover focus",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.titleFor(role.value)) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "pt-0",
                                        attrs: {
                                          id: role.value,
                                          variant: "link",
                                          "aria-label": role.text + " button",
                                        },
                                        on: {
                                          click: () =>
                                            (person.role = role.value),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(role.text) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "b-button",
            {
              attrs: { variant: "link", "aria-label": "add contact button" },
              on: { click: _vm.addPerson },
            },
            [
              _c("feather-icon", { attrs: { type: "plus-circle" } }),
              _vm._v("\n      Add another contact\n    "),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "b-button-group",
        { staticClass: "float-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-2",
              attrs: { variant: "danger", "aria-label": "send invite button" },
              on: { click: _vm.cancelInvite },
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _c(
            "b-button",
            {
              attrs: {
                variant: "primary",
                disabled: !_vm.inviteFormIsValid,
                "aria-label": "send invite button",
              },
              on: { click: _vm.sendInvite },
            },
            [_vm._v("\n      Send Invite\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }