var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-multiselect", {
    attrs: {
      placeholder: _vm.placeholderText,
      label: _vm.label,
      "track-by": _vm.identifier,
      options: _vm.options,
      multiple: _vm.multiple,
      loading: _vm.isLoading,
    },
    on: {
      input: _vm.inputEvent,
      select: _vm.selectEvent,
      remove: _vm.removeEvent,
      searchChange: _vm.searchChangeEvent,
      open: _vm.openEvent,
      close: _vm.closeEvent,
    },
    model: {
      value: _vm.selection,
      callback: function ($$v) {
        _vm.selection = $$v
      },
      expression: "selection",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }